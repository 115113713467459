import React from 'react';

export default function About() {
    return (
        <div className="about-wrapper">
            <div>
                <h1>{'Hi, I\'m Amanpreet! 👋'}</h1>
                <blockquote>
                    <p>💻 Software Engineer</p>
                </blockquote>
                <p className="about-links-container">
                    <a href="https://twitter.com/ammy_batra23">
                        <img
                            src="https://img.shields.io/twitter/follow/ammy_batra23?style=social"
                            alt="Twitter: @ammy_batra23"
                        />
                    </a>
                    <a href="https://github.com/amanpreetbatra">
                        <img
                            src="https://img.shields.io/github/followers/amanpreetbatra?label=follow&style=social"
                            alt="GitHub: @amanpreetbatra"
                        />
                    </a>
                    <a href="https://www.linkedin.com/in/amanpreetbatra/">
                        <img
                            alt="Linkedin: @amanpreetbatra"
                            src="https://img.shields.io/badge/-amanpreetbatra-blue?style=flat-square&logo=Linkedin&logoColor=white&link=https://www.linkedin.com/in/amanpreetbatra/"
                        />
                    </a>
                    <a href="mailto:singh.amanpreet23@outlook.com">
                        <img
                            src="https://img.shields.io/badge/Outlook-@amanpreetbatra-blue"
                            alt="outlook: @amanpreetbatra"
                        />
                    </a>
                </p>
                <p>
                    My passion lies in solving challenging problems, designing algorithms, and
                    communicating complex ideas to non-technical stakeholders.
                </p>
                <p>
                    I always look to exceed expectations and am effective both working as an
                    individual and as part of a team.
                </p>
                <p>
                    In my spare time, I like to travel 🚶, play games 🎮️, listen to music 🎧 and
                    watch movies 📺.
                </p>
                <p>
                    I enjoy learning new things and connecting with people across a range of
                    industries. If you ever want to bounce ideas off of me, please feel free to
                    reach out on twitter or email. 😄
                </p>
                <hr className="about-separator" />
                <h2>⚡ Technical Skills ⚡</h2>
                <table className="about-skills-table">
                    <tr>
                        <th>Area</th>
                        <th>Proficiencies</th>
                    </tr>
                    <tr>
                        <td>Machine Learning and Data Science</td>
                        <td>Pyspark, Pandas, Scikit learn, </td>
                    </tr>
                    <tr>
                        <td>DevOps</td>
                        <td>Grafana, Prometheus,Docker, Ansible,Nginx, Makefile</td>
                    </tr>
                    <tr>
                        <td>UI Frameworks</td>
                        <td>Material-UI, Bootstrap</td>
                    </tr>
                    <tr>
                        <td>Web technologies and frameworks</td>
                        <td>React, Jinja2, HTML, CSS , JQuery,Django, Flask, Dash </td>
                    </tr>
                    <tr>
                        <td>Databases</td>
                        <td>MongoDB, MySQL, PostgreSQL, Cassandra</td>
                    </tr>
                    <tr>
                        <td>Misc</td>
                        <td>Git, Eslint, Prettier, Typescript, GitHub Actions</td>
                    </tr>
                    <tr>
                        <td>Languages</td>
                        <td>Python, Java , C</td>
                    </tr>
                </table>
            </div>
        </div>
    );
}
