import React, {useEffect, useRef, useState} from 'react';
import emailjs from "@emailjs/browser";

export default function Contact() {
    const [loading,setLoading] = useState(false);
    const email = useRef(null);
    const name = useRef(null);
    const message = useRef(null);
    useEffect(() => emailjs.init("QD3FvrwI_g9rarxu4"), []);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            await  emailjs.send('service_cwkrulv', 'template_lc4ag28', {
                to_email: email.current.value, // Replace with the recipient's email
                message: message.current.value, // Replace with your email content
                name: name.current.value
            });
            // eslint-disable-next-line
            alert("email successfully sent check inbox");
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        } finally {
            setLoading(false);
            // eslint-disable-next-line
            console.log(loading)
        }
    }
    return (
        <div className="contact-container">
            <article>
                <h2 className="contact-header">Contact Me</h2>
                <p className="contact-text">
                    I’d love to have a chat to see how I can help you.
                    The best first step is for us to discuss your project during a free consultation.
                    Then we can move forward from there.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="contact-form-container">
                        <div className="contact-form-name">
                            <input
                                className="contact-message-form-field"
                                id="name"
                                type="text"
                                name="name"
                                ref={name}
                                placeholder="Name"
                            />
                        </div>
                        <div className="contact-form-email">
                            <input
                                className="contact-message-form-field"
                                id="email"
                                type="email"
                                name="email"
                                ref={email}
                                placeholder="Email"
                            />
                        </div>
                        <div className="clearfix">
                            <textarea
                                className="contact-message-form-field contact-message-textarea"
                                id="message"
                                name="message"
                                placeholder="Message"
                                spellCheck="false"
                                defaultValue=""
                                rows={5}
                                maxLength={2000}
                                ref={message}
                            />
                        </div>
                        <div className="clearfix">
                            <input
                                type="submit"
                                id="send"
                                defaultValue="Submit"
                                className="contact-submit-btn"
                            />
                        </div>
                    </div>
                </form>
            </article>
        </div>
    );
}
