import React from 'react';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { ReactComponent as WorkIcon } from '../assets/icons/work.svg';
import { ReactComponent as SchoolIcon } from '../assets/icons/school.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';

export default function Home() {
    const typeSchool = 'school';
    const typeWork = 'work';
    const timelineData = [


        {
            id: 0,
            date: 'August 2023 - Present',
            title: 'Continent 8 Technologies',
            subtitle: 'Monitoring Engineer - Part-time Contract',
            type: typeSchool,
        },
        {
            id: 0,
            date: 'May 2023 - August 2023',
            title: 'Continent 8 Technologies',
            subtitle: 'Monitoring Intern',
            type: typeSchool,
        },
        {
            id: 0,
            date: 'Sept 2022 - Present',
            title: 'Concordia University, Montreal, Canada',
            subtitle: 'Masters of Applied Computer Science',
            type: typeSchool,
        },
        {
            id: 1,
            date: 'July 2021 - July 2022',
            title: 'Tata Consultancy Services Ltd.',
            subtitle: 'System Engineer',
            type: typeWork,
        },
        {
            id: 1,
            date: 'Jan 2021 - July 2021',
            title: 'Tata Consultancy Services Ltd.',
            subtitle: 'Assistant System Engineer',
            type: typeWork,
        },
        {
            id: 1,
            date: 'Apr 2020 – Dec 2020',
            title: 'Nioeye Solutions Pvt. Ltd.',
            subtitle: 'Backend Developer',
            type: typeWork,
        },
        {
            id: 2,
            date: 'Feb 2020 – Apr 2020',
            title: 'I-FitMash, Noida, India',
            subtitle: 'Backend Developer- Freelance',
            type: typeWork,
        },
        {
            id: 3,
            date: 'June 2019 – July 2019',
            title: 'Nuage Biztech Pvt. Ltd.',
            subtitle: 'Python Intern',
            type: typeWork,
        },
        {
            id: 4,
            date: 'Aug 2016 - Sep 2020',
            title: 'Guru Gobind Singh Inderprastha University',
            subtitle: 'Bachelor of Technology, Information Technology',
            type: typeSchool,
        },
        {
            id: 5,
            date: 'Apr 2016',
            title: "St. Mary's Sr. Sec. School, New Delhi",
            subtitle: 'High School',
            type: typeSchool,
        },
    ];

    return (
        <VerticalTimeline
            className="vertical-timeline-container"
            animate={false}
            lineColor="#000"
        >
            {timelineData.map(item => (
                    <VerticalTimelineElement
                        id={item.id}
                        key={item.id}
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#aaa', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid #aaa' }}
                        date={item.date}
                        iconStyle={{ background: '#aaa', border: '#000' }}
                        icon={item.type === typeWork ? <WorkIcon /> : <SchoolIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">{item.title}</h3>
                        <h4 className="vertical-timeline-element-subtitle">{item.subtitle}</h4>
                    </VerticalTimelineElement>
                ))}
            <VerticalTimelineElement
                id="vertical-timeline-icon-star"
                iconStyle={{ background: '#aaa', border: '#000' }}
                icon={<StarIcon />}
            />
        </VerticalTimeline>
    );
}
